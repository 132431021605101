<template>
  <div class="service-item">
    <div class="service-item__title">
      <span class="service-item__title--name">{{ service.name }}</span>
      <span class="service-item__title--edit">
        <v-btn small text icon @click="customServiceModal = true">
          <!-- <IconEdit size="22" /> -->
          <v-icon dark color="primary" size="22"> mdi-pencil </v-icon>
        </v-btn>
      </span>
      <span class="service-item__title--checkbox">
        <v-checkbox
          :input-value="isSelected"
          hide-details
          color="success"
          @change="onChangeCheckbox"
        >
        </v-checkbox>
      </span>
    </div>
    <div class="service-item__body">
      <div v-if="loader" class="text-center pt-2">
        <v-progress-circular
          :size="40"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <template v-if="!loader && isSelected">
        <div class="service-item__body--description">
          {{ service.description }}
        </div>

        <div class="service-item__body--total">
          <span>Total produits et services: </span>
          <span>{{ price }} €</span>
        </div>
      </template>
    </div>
    <EditCustomServiceModal
      :show="customServiceModal"
      :model="service"
      @closeEditModal="customServiceModal = false"
      @updateCustomService="(payload) => $emit('updateCustomService', payload)"
    />
  </div>
</template>

<script>
import { RoomServicesService } from "@/services/roomServices.sevice.js";
export default {
  name: "ServiceCustomItem",
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
    EditCustomServiceModal: () =>
      import("~cp/Services/CustomServiceModal/EditCustomServiceModal"),
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
    roomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      loader: false,
      customServiceModal: false,
    };
  },
  computed: {
    isSelected() {
      return !!this.service?.roomService;
    },
    price() {
      return (this.service?.default_price || 0).toFixed(2);
    },
  },
  methods: {
    onChangeCheckbox(payload) {
      if (payload) {
        this.createRoomService();
      } else {
        this.deleteRoomService();
      }
    },
    createRoomService() {
      RoomServicesService.createQuotaRoomService({
        quota_room_id: this.roomId,
        service_id: this.service.id,
        amount: 1,
      }).then(() => this.$emit("updateQuotaRoomService"));
    },
    deleteRoomService() {
      if (!this.service?.roomService?.id) return false;
      RoomServicesService.deleteQuotaRoomService(this.service.roomService.id)
        .then(() => this.$emit("updateQuotaRoomService"))
        .catch((ex) => console.log(ex));
    },
  },
};
</script>

<style src="./ServiceItem.scss" lang="scss" scoped></style>